$fontSize3: 1.1rem;

#GuestUserCard {
  --background: rgba(var(--ion-color-board-rgb), 0.2) !important;

  ion-card-header {
    font-size: $fontSize3 !important;
  }
  .right {
    float: right;
    margin-top: -30px;
  }

  ion-card-title {
    font-size: $fontSize3 !important;
  }

  ion-card-content {
    font-size: $fontSize3 !important;
    padding: 20px;
  }

  .buttons {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    ion-button {
      display: block;
    }
  }
}
