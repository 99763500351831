#MealCategoriesListPage_Content {
  .day {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
    flex: 1;

    ion-chip {
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.enabled1 {
        background-color: var(--ion-color-tertiary);
        color: var(--ion-color-tertiary-contrast);
      }
    }

    .time {
      font-size: 0.7rem;
      font-weight: normal;
      color: var(--ion-text-color);
    }
  }

  ion-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ion-title {
      font-family: 'Lato-Light';
      text-transform: capitalize;
      font-size: 0.9rem;
      padding-left: 0 !important;
    }

    .buttons {
      ion-icon {
        margin-left: 16px;
        font-size: 1.15rem;
      }

      .not-visible {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.2);
      }

      .visible {
        color: var(--ion-color-tertiary);
      }
    }
  }

  .days-row {
    justify-content: center;
    display: flex;
  }
}

html.plt-ios {
  #MealCategoriesListPage_Content {
    ion-card-header {
      ion-title {
        text-transform: capitalize;
      }
    }
  }
}
