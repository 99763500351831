#ResetPasswordPage {
  background-color: var(--ion-color-medium);

  #LogoContainer {
    display: flex;
    justify-content: center;
    padding: 20px;

    img {
      width: 35%;
      height: 35%;
      text-align: center;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    background: none;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--ion-text-color) !important;
  }

  ion-input {
    color: var(--ion-text-color) !important;
  }

  ion-text {
    text-align: center;
    text-transform: capitalize;

    h1 {
      font-size: 1.9rem;
    }

    h6 {
      font-size: 0.8rem;
      cursor: pointer;
    }
  }

  ion-spinner {
    margin-left: 10px;
  }

  ion-button.register-button {
    --background: #629656;
  }

  ion-content {
    align-self: center;
    max-width: 400px;
    background: transparent !important;
    background-color: transparent !important;
    --background: none;
  }

  .forgot-password {
    display: flex;
    justify-content: center;
  }

  .error {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }

  html.md {
    ion-label.forced-floating {
      transform: translate3d(0, 50%, 0) scale(0.75) !important;
    }
  }

  html.plt-ios {
    ion-label.forced-floating {
      transform: translate3d(0, 10%, 0) scale(0.82) !important;
    }
  }
}

html.plt-ios {
  #LoginPage {
    ion-text.forgot-password {
      text-transform: capitalize !important;
    }
  }
}
