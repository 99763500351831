#RecipeMenu {

  ion-item {
    cursor: pointer;
  }

  ion-icon {
    color: var(--ion-text-color) !important;
    margin-right: 14px
  }
}
