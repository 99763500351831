#RedirectScreen {
  background-color: var(--ion-color-medium);
  height: 100%;

  #LogoContainer {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;

    img {
      width: 53px;
      height: 53px;
      text-align: center;
      margin-top: 10px;
      margin-right: 12px;
      margin-left: 12px;
    }

    div#g {
      font-family: 'Lato-Regular';
      font-size: 28px !important;
      text-transform: uppercase;
      color: var(--ion-color-medium-contrast);
    }
    div#b {
      font-family: 'Lato-Regular';
      font-size: 28px !important;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--ion-color-medium-contrast);
    }
  }

  #LinksContainer {
    margin-top: 20px;
    text-align: center;

    img {
      margin-top: 10px;
      width: 230px;
    }
  }

  #SpinnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 40px;

    ion-spinner {
      width: 70px;
      height: 70px;
      color: gray;
    }
  }
}
