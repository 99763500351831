.progress-line {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--ion-color-light);
  border-radius: 2px;
  overflow: hidden;
  transition: width 0.3s;

  .progress-line__line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--ion-color-tertiary);
    border-radius: 2px;
    transition: width 0.3s;
  }
}
