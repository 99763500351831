html.plt-ios.plt-hybrid {
  #MealCategoriesListPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #MealCategoriesListPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#MealCategoriesListPage_Content {
  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: #888;
  }
}
