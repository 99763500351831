#TabButtons {
  border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.5) 1px;

  .md {
    
    ion-segment-button {
    background-color: var(--ion-color-medium);

    ion-label {
        font-size: 0.75rem;
        font-weight: normal;
      }

      ion-icon,
      ion-label {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.7);
      }

      &.segment-button-checked {
        ion-label,
        ion-icon {
          font-weight: normal;
          color: var(--ion-color-tertiary);
        }
      }
    }
  }
}

html.plt-ios {
  #TabButtons {
    ion-segment-button {
      // --background-checked: yellow !important;
      // --background-focused: yellow !important;
      --color: rgba(var(--ion-color-light-contrast-rgb), 0.7);
      --color-checked: var(--ion-color-tertiary-contrast);

      // --background: red;	//Background of the segment button
      // --background-checked: red;	//Background of the checked segment button
      // --background-focused: red;	//Background of the segment button when focused with the tab key
      // --background-focused-opacity: red;	//Opacity of the segment button background when focused with the tab key
      // --background-hover: red;	//Background of the segment button on hover
      // --background-hover-opacity: red;	//Opacity of the segment button background on hover
      // --border-color: red;	//Color of the segment button border
      // --border-radius: red;	//Radius of the segment button border
      // --border-style: red;	//Style of the segment button border
      // --border-width: red;	//Width of the segment button border
      // --color-checked: red;	//Color of the checked segment button
      // --color-focused: red;	//Color of the segment button when focused with the tab key
      // --color-hover: red;	//Color of the segment button on hover
      // --indicator-box-shadow: red;	//Box shadow on the indicator for the checked segment button
      --indicator-color: var(--ion-color-tertiary) !important;

      margin-top: 5px;
      margin-bottom: 5px;
      padding-top: 6px;
      padding-bottom: 6px;

      ion-label {
        font-size: 0.9rem;
      }

      ion-icon {
        font-size: 1rem;
        // display: none;
      }

      // &.segment-button-checked {
      //   // --background-color: var(--ion-color-tertiary);
      //   ion-label,
      //   ion-icon {
      //     color: var(--ion-text-color) !important;
      //   }
      // }
    }
  }
}
