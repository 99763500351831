#RecipeEditPage_Content #DetailsForm {
  --ion-grid-column-padding: 0;
  --ion-grid-padding: 0;

  ion-grid {
    --ion-grid-column-padding: 0;
    --ion-grid-padding: 0;
  }
  ion-row {
    --ion-padding: 0;
  }
  ion-col {
    --ion-padding: 0;
  }

  .buttons {
    margin-left: 20px;
  } 
}
