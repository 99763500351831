#BottomTabs {
  z-index: 1000;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0;

  .mdc-tab-scroller__scroll-content {
    justify-content: stretch;
    flex-direction: row;
    overflow-y: hidden;

    .mdc-tab {
      flex: 1;
      padding: 0;
      height: 54px;

      .mdc-tab__content {
        flex-direction: column;
      }
    }
  }

  .fa-tasks {
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 9px;
  }

  ion-label {
    margin-top: -15px;
    text-transform: capitalize;
    font-size: 0.8rem !important;
    font-family: 'Lato-Light';
  }
}

html.plt-ios {
  #BottomTabs {
    ion-label {
      text-transform: capitalize;
      font-size: 0.8rem !important;
    }
  }
}

html.plt-ios.plt-hybrid {
  #BottomTabs {
    padding-bottom: calc(var(--ion-safe-area-bottom) - 15px);
  }
}
