html.plt-ios.plt-hybrid {
  #ThemeGenPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #ThemeGenPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#ThemeGenPage_Content {
  .color-demo {
    height: 10px;
    width: 100%;
    border-radius: 8px;
    border: solid #999 0.5px;
  }
}
