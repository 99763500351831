#MealCategoryEditPage_Content #DetailsForm #DaysGrid {
  --ion-grid-column-padding: 0;
  --ion-grid-padding: 0;

  // ion-item,
  // ion-col {
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }

}
