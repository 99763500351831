html.plt-ios.plt-hybrid {
  #BotSessionsListPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #BotSessionsListPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

html.plt-ios {
  #BotSessionsListPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
  }
}

#BotSessionsListPage_Content {
  #BotOptions {
    ion-col {
      padding-right: 0;
      padding-bottom: 10px;
    }
    ion-card {
      height: 100%;
      margin-bottom: 30px;
      cursor: pointer;

      ion-card-header {
        padding: 4px 14px;
        background-color: var(--ion-color-medium);
        margin-bottom: 8px;

        ion-icon,
        i,
        svg {
          font-size: 1.2rem;
          margin-right: 8px;
          margin-left: -4px;
          margin-bottom: -3px;
          color: var(--ion-text-color) !important;
        }
        i {
          font-size: 1.5rem !important;
        }
        svg {
          margin-bottom: 0px;
        }

        ion-card-title {
          font-size: 1.4rem;
          // text-align: center;
          margin: 8px;
          color: var(--ion-text-color);
        }
      }

      .bot-option-image {
        width: 100%;
        max-width: 100%;
        height: 90px; /* Set the desired height */
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; /* Ensure the image covers the container */
          object-position: center right; /* Display the top right of the image */
          position: absolute;
        }
      }

      ion-card-content {
        padding: 0 14px;
      }

      .actions {
        position: absolute;
        bottom: 6px;
        right: 6px;
      }
    }
  }

  ion-text#past {
    display: block;
    font-size: 1.3rem;
    padding-top: 20px;
    padding-left: 20px;
    line-height: 40px !important;
  }

  ion-list {
    margin-top: -7px;
    z-index: 100;
    padding-left: 12px;
    padding-right: 12px;
  }

  ion-item-divider {
    padding: 5px;
    padding-left: 16px;
    text-transform: capitalize;
  }

  .name {
    margin-left: 13px;
    flex: 1;
    align-self: center;
    line-height: 1.8rem;
    cursor: pointer;
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .no-sessions {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: #888;
  }
}
