:root {
  --ion-font-family: Lato-Regular;
  --ion-text-color: var(--ion-color-dark);
  --ion-background-color: var(--ion-color-light);
}

.MuiInputBase-input {
  font-family: var(--ion-font-family) !important;
}

ion-header {
  z-index: 1;

  .title-default {
    font-family: Lato-Light;
    font-size: 16px;
    text-transform: capitalize;
  }
}

.header-md:after {
  height: 0;
}

$fontSize: 0.95rem;
$fontSize2: 1.5rem;

ion-input,
ion-item {
  font-size: $fontSize;
  input {
    caret-color: auto;
  }
}

ion-textarea {
  textarea {
    caret-color: auto;
  }
}

ion-item {
  --border-color: rgba(var(--ion-color-light-contrast-rgb), 0.15);
}

.item-has-focus {
  --highlight-color-focused: transparent !important;
  // --border-width: 0 !important;
  // --border-color: tr !important;

  ion-label {
    // color: var(--ion-color-primary-contrast) !important;
    color: inherit !important;
  }
}

div,
span,
h6,
button,
input {
  font-size: $fontSize;
}

ion-select {
  font-size: $fontSize;
}

ion-select-popover {
  ion-radio::part(mark) {
    border-color: var(--ion-color-medium-contrast) !important;
  }
}

ion-card-title {
  font-size: $fontSize;
}

ion-button {
  font-size: $fontSize;
  text-transform: none;
  --padding-top: 24px;
  --padding-bottom: 24px;
  --margin-bottom: 20px;
  margin-bottom: 24px;
  &[color='primary'] {
    i {
      color: var(--ion-color-primary-contrast) !important;
    }
  }
  &[color='secondary'] {
    i {
      color: var(--ion-color-secondary-contrast) !important;
    }
  }
  &[color='dark'] {
    i {
      color: var(--ion-color-dark-contrast) !important;
    }
  }
  &[color='medium'] {
    i {
      color: var(--ion-color-medium-contrast) !important;
    }
  }
  &[color='danger'] {
    i {
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }
  &[color='tertiary'] {
    i {
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }
  &[color='success'] {
    i {
      color: var(--ion-color-success-contrast) !important;
    }
  }
  &[color='primary'][fill='clear'] {
    i {
      color: var(--ion-color-primary) !important;
    }
  }
  &[color='secondary'][fill='clear'] {
    i {
      color: var(--ion-color-secondary) !important;
    }
  }
  &[color='dark'][fill='clear'] {
    i {
      color: var(--ion-color-dark) !important;
    }
  }
  &[color='medium'][fill='clear'] {
    i {
      color: var(--ion-color-medium) !important;
    }
  }
  &[color='danger'][fill='clear'] {
    i {
      color: var(--ion-color-danger) !important;
    }
  }
  &[color='tertiary'][fill='clear'] {
    i {
      color: var(--ion-color-tertiary) !important;
    }
  }
  &[color='success'][fill='clear'] {
    i {
      color: var(--ion-color-success) !important;
    }
  }
}

ion-title {
  padding-left: 10px;
  font-size: $fontSize2;
}

ion-label {
  // font-size: $fontSize;
}

ion-datetime {
  font-size: $fontSize;
}

html.plt-ios {
  ion-header {
    ion-title {
      --border-width: 0 0 0;
      padding-left: 80px;
      text-transform: capitalize !important;
    }
  }
}

html.md {
  ion-label.forced-floating {
    transform: translate3d(0, 50%, 0) scale(0.75) !important;
  }
}

html.plt-ios {
  ion-label.forced-floating {
    transform: translate3d(0, 10%, 0) scale(0.82) !important;
  }
}

// Avatars
.UserAvatar--dark {
  color: #fff;
  div {
    // border: solid var(--ion-text-color) .5px;
  }
}
.UserAvatar--light {
  color: #222;
  div {
    // border: solid var(--ion-text-color) .5px;
  }
}

.alert-button.sc-ion-alert-md {
  color: var(--ion-color-tertiary);
}

.alert-message.sc-ion-alert-md {
  color: var(--ion-text-color);
}

.alert-button.sc-ion-alert-ios {
  color: var(--ion-color-dark);
}

.alert-message.sc-ion-alert-ios,
.alert-title.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-ios {
  color: var(--ion-color-dark);
}

.alert-radio-label.sc-ion-alert-md {
  color: var(--ion-text-color) !important;
  -webkit-padding-start: 45px !important;
  padding-inline-start: 45px !important;
  -webkit-padding-end: 0 !important;
  padding-inline-end: 0 !important;
}

.alert-radio-group {
  border-top: none !important;
  border-bottom: none !important;
}

button[aria-checked='true'].sc-ion-alert-md {
  .alert-radio-icon {
    border-color: var(--ion-color-tertiary) !important;
  }
  .alert-radio-inner {
    background-color: var(--ion-color-tertiary) !important;
  }
}

[popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background: var(--ion-color-light) !important;
  transition: background-color 5000s ease-in-out 0s;
  font-family: var(--ion-font-family) !important;
  caret-color: var(--ion-color-light-contrast) !important;
  -webkit-text-fill-color: var(--ion-color-light-contrast) !important;
  -webkit-text-caret-color: var(--ion-color-light-contrast) !important;
}
