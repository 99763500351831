// @import '../../../../node_modules/yet-another-react-lightbox/dist/styles.css';

#ImagesGridV2 {
  box-sizing: border-box;

  // &.colcnt-1 {
  //   .grid-container {
  //     column-count: 1;
  //   }
  // }
  // &.colcnt-2 {
  //   .grid-container {
  //     column-count: 2;
  //   }
  // }
  // &.colcnt-3 {
  //   .grid-container {
  //     column-count: 3;
  //   }
  // }
  // &.colcnt-4 {
  //   .grid-container {
  //     column-count: 4;
  //   }
  // }
  // &.colcnt-5 {
  //   .grid-container {
  //     column-count: 5;
  //   }
  // }
  // &.colcnt-6 {
  //   .grid-container {
  //     column-count: 6;
  //   }
  // }
  // &.auto {
  //   .grid-container {
  //     column-count: 5;
  //   }
  // }

  $height: 160px;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Dynamic columns */
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-template-columns: auto fit-content($height);
    // grid-template-columns: 50% 50%;
    // grid-template-columns: auto repeat(auto-fit, 250px);
    grid-template-columns: 50% 50%;
    grid-template-columns: repeat(auto-fit, minmax($height, 1fr)); /* Dynamic columns */
    // column-count: 5;

    // grid-template-columns: max-content max-content;
    // grid-auto-flow: row;
    gap: 10px; /* Adjust spacing as needed */
    // align-content: start;
    // justify-content: start;
    padding-left: 10px;
    padding-top: 10px;
    // padding-bottom: 10px;
    padding-right: 10px;
    // justify-content: center;

    .grid-item {
      &.blank {
        width: 0;
        height: 0;
        visibility: hidden;
        margin-top: -10px;
        margin-bottom: -10px;
        padding: 0;
        margin: 0;
      }
      &:not(.blank) {
        position: relative;
        width: 100%;
        // margin: 10px;
        // margin-left: 10px;
        // margin-right: 10px;
        // padding: 10%; /* 1:1 aspect ratio */
        // padding-bottom: 100%; /* 1:1 aspect ratio */
        justify-self: start;
        // max-width: $height;
        // min-width: 200px;
        height: $height;
        // max-height: $height;
        // display: grid;
        // grid-template-rows: 1fr auto; /* 1fr = flexible space, auto = content height */
        cursor: pointer;
        // break-inside: avoid;
        // margin-bottom: 10px;
        background-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
        border: 1px solid var(--ion-color-light-shade);
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        text-align: center;
        // vertical-align: middle;
        align-content: center;
      }

      .img {
        z-index: 99;
        // grid-row: 1 / 2;
        width: 100%;
        height: calc(100% - 60px);
        height: 100%;
        justify-content: center;
        justify-self: center;
        align-items: center;
        align-content: center;

        img:not(.placeholder) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 10% 0%;
          z-index: 99;
        }

        img.placeholder {
          margin: auto;
          margin-top: -60px;
          width: 60px;
          height: 60px;
        }
      }

      .title {
        // grid-row: 2 / 3;
        position: relative;
        // z-index: 1000;
        margin-top: -60px;
        background: rgba(var(--ion-color-dark-contrast-rgb), 0.9);
        // background: blue !important;
        color: rgba(var(--ion-color-dark-rgb), 0.9);
        // text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        font-weight: bold;
        text-align: center;
        width: 100%;
        height: 60px;
        padding: 10px;
        //only show 2 lines of text with an ellipsi
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        // line-height: 65px;
        // overflow: hidden;
        // text-overflow: ellipsis;

      }
    }
  }
}

@media (max-width: 1200px) {
  #ImagesGrid.auto {
    .grid-container {
      column-count: 4;
    }
  }
}
@media (max-width: 800px) {
  #ImagesGrid.auto {
    .grid-container {
      column-count: 3;
    }
  }
}
@media (max-width: 600px) {
  #ImagesGrid.auto {
    .grid-container {
      column-count: 2;
    }
  }
}
