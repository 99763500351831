#DayGroup #MealCategoryRow {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 14px;
  cursor: pointer;
  height: 28px;

  .category-name {
    align-self: center;
    flex: 1;
    padding-left: 10px;
    white-space: nowrap;
  }

  .meals {
    flex: 4;
    display: flex;
    align-items: center;

    .meal {
      margin-right: 4px;
      border-radius: 8px;
      border: solid #777 1px;
      padding: 2px 4px;
      white-space: nowrap;
    }

    .add-button {
      display: flex;
      align-content: center;
      border-radius: 50%;
      border: solid #555 1px;
      padding: 4px;
    }
  }
}
