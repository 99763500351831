html.plt-ios.plt-hybrid {
  #RecipeEditPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #RecipeEditPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#RecipeEditPage_Header {
  animation: slide-up 0.4s ease;
}

#RecipeEditPage_Content {
  animation: slide-up 0.4s ease;
}


