#RegisterPage {
  background-color: var(--ion-color-medium);

  #LogoContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: calc(15px + var(--ion-safe-area-top));

    img {
      width: 65%;
      height: 65%;
      text-align: center;
    }
  }

  ion-content {
    align-self: center;
    max-width: 450px;
    background: transparent !important;
    background-color: transparent !important;
    --background: none;
  }

}
