html.plt-ios.plt-hybrid {
  #ListItemsListPage_Header {
    ion-toolbar {
      padding-top: 0;
    }
  }
}

#ListItemsListPage_Header {
  animation: slide-up 0.4s ease-in;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  i {
    color: var(--ion-color-secondary-contrast) !important;
  }

  ion-toolbar {
    --min-height: 46px;
  }
}

html.plt-ios {
  #ListItemsListPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
  }
}

#ListItemsListPage_Content {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  
  ion-list {
    padding-top: 0;
    z-index: 100;
  }

  ion-item-divider {
    padding: 5px;
    padding-left: 16px;
    text-transform: capitalize;
  }

  ion-row {
    .quantity-indicator {
      border-radius: 50%;
      border: solid #555 1px;
      color: #888;
      font-family: Arial, Helvetica, sans-serif;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      align-self: center;
      cursor: pointer;
    }

    .notes {
      color: #888;
      padding: 0;
      margin-top: -10px;
    }

    .name {
      margin-left: 13px;
      flex: 1;
      align-self: center;
      line-height: 1.8rem;
      cursor: pointer;
    }

    .gotten {
      text-decoration: line-through;
      color: #777;
    }
  }

  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: rgba(var(--ion-color-light-contrast-rgb), 0.8);
  }
}
