#SpinnerV2 {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--ion-color-light-rgb), 0.6);
  width: 200px;
  height: 200px;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;

  .loading-spinner {
    width: 100px;
    height: 100px;
  }

  .listening-spinner {
    width: 120px;
    height: 120px;
  }

  ion-spinner {
    width: 70px;
    height: 70px;
    color: gray;
  }
}
