#MealCategoryEditPage_Content #DayRow {
  ion-toggle {
    padding-left: 0px;
    padding-right: 8px;
  }

  .end {
    display: flex;
    align-items: center;
  }

  .MuiInputBase-input {
    width: 70px;
    font-weight: bold;
    font-size: 1rem !important;
    color: rgba(var(--ion-color-light-contrast-rgb), 0.6);
  }
}

html.plt-ios {
  #MealCategoryEditPage_Content #DayRow {
    ion-toggle {
      width: 30px;
      height: 20px;
      --handle-width: 16px;
      // --width: 10px;
      // --height: 10px;
    }
  }
}
