#MealCategoryEditPage_Content #DetailsForm {
  ion-icon {
    font-size: 20px !important;
  }

  .not-visible {
    color: rgba(var(--ion-color-light-contrast-rgb), 0.2);
  }

  .visible {
    color: var(--ion-color-tertiary);
  }

  .up-more {
    transform: translate3d(0, 20%, 0) scale(0.75);
  }

  .MuiInputBase-root {
    color: inherit;
    border-bottom: none !important;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }

  .circle-picker {
    margin-top: 22px;
    align-self: center;
    margin-bottom: 1px !important;
  }
}
