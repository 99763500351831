#MealEditPage_Content #ItemsList {
  .wrapper {
    padding: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  ion-card {
    flex: auto;
    margin: 6px;
    background: rgba(var(--ion-color-board-rgb), 0.25) !important;

    .title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
    }

    ion-list {
      background: rgba(var(--ion-color-board-rgb), 0.25) !important;
    }

    // ion-item {
    //   --background: rgba(var(--ion-color-board-rgb), 0) !important;
    // }
  }

  ion-card-header {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;

    ion-checkbox {
      --size: 20px;
      margin-right: 6px;
      margin-top: -7px;
      margin-bottom: -4px;
    }

    ion-title {
      font-size: 0.9rem;
      text-transform: capitalize;
      font-family: 'Lato-Light';
    }

    .more-menu-icon {
      position: absolute;
      right: 0;
      margin-top: -20px;
      cursor: pointer;
      font-size: 1.2rem;
    }
  }

  ion-card-content.no-items {
    text-align: center;
    padding: 14px 0;
    font-size: 0.7rem;
    background: rgba(var(--ion-color-board-rgb), 0.25) !important;
    height: 100%;
  }

  ion-card-content {
    padding: 0;

    ion-list {
      padding-top: 2px !important;
    }

    ion-checkbox {
      --border-color-checked: rgba(var(--ion-color-light-contrast-rgb), 0.7);
      --border-color: rgba(var(--ion-color-light-contrast-rgb), 0.2);
      --checkmark-color: rgba(var(--ion-color-light), 0.6);
    }

    .del-btn {
      z-index: 1000;
      cursor: pointer;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.17);
    }

    #ItemRow {
      height: var(--list-row-height);
      display: flex;
      align-items: center;
      border-bottom: solid var(--ion-color-light-shade) 1px;
      padding-right: 14px;
      border-left: solid #999 3px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      .name {
        margin-left: 10px;
        flex: 1;
        align-self: center;
        line-height: 1.8rem;
        color: var(--ion-text-color);
      }

      &.checked {
        .name {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
        }
      }

      .icon-container {
        display: inline-flex;
        padding-left: 16px;
        height: 100%;
        align-items: center;
        cursor: pointer;
    
        i {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.2) !important;
          min-width: 22px;
        }
    
        &.checked {
          i {
            color: rgba(var(--ion-color-light-contrast-rgb), 0.6) !important;
          }
        }
      }
      
      .cart {
        margin-left: -21px;
        margin-top: 1px;
        z-index: 100;
        font-size: 16px;
        opacity: 0.6;
        margin-right: 7px;
        color: var(--ion-color-light-contrast) !important;
        margin-left: -16px;
        font-size: 9px;
        // position: relative;
        // top: -5px;
        cursor: pointer;
      }
    }

    // .qty-section {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   justify-items: center;

    //   .qty {
    //     margin-top: -1px;
    //   }

    //   .qty-button {
    //     width: 30px;
    //     height: 30px;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    //     justify-items: center;
    //   }

    //   .minus-button {
    //     margin-right: 6px;
    //   }

    //   .plus-button {
    //     margin-left: 6px;
    //   }
    // }
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }
}

html.plt-ios {
  #MealEditPage_Content #ItemsList {
    ion-card {
      .title-row {
        text-transform: capitalize;
      }
    }

    ion-card-header {
      ion-title {
        text-transform: capitalize;
      }
    }
  }
}
