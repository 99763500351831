html.split-pane-visible {
  #MobileNudgeCard {
    width: 254px !important;
  }
}

$color: rgba(var(--ion-color-light-contrast-rgb), 0.5);

#MobileNudgeCard {
  display: block;
  position: absolute;
  bottom: -10px;
  width: 284px;
  left: -10px;
  height: 94px;
  margin: 20px !important;

  .blurred {
    filter: blur(1px);
    z-index: -1;
  }

  #UnlockMessage {
    z-index: 1;
    background: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    border: solid 3px var(--ion-color-secondary-tint);
    display: flex;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    top: 18px;
    left: 30px;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    ion-text {
      color: rgba(var(--ion-color-tertiary-contrast-rgb), 0.5);
    }
    
    i {
      color: var(--ion-color-tertiary-contrast) !important;
      margin-right: 10px;
      margin-top: 2px;
    }
  }

  ion-text {
    margin-top: -3px;
    display: block;
    width: 130px;
    color: $color;
  }

  .chips {
    margin-left: -10px;

    ion-chip {
      padding: 12px;
      padding-top: 16px;
    }
    ion-chip.android {
      width: 90px;
      margin-left: 0;
    }
    ion-chip.ios {
      width: 60px;
    }
    ion-text {
      font-size: 0.8rem;
      color: $color !important;
      vertical-align: middle;
    }
    i {
      color: $color !important;
      display: block;
      margin-top: -6px;
      margin-right: 2px;
      padding-top: 0;
    }
  }

  img {
    display: block;
    width: 80px;
    margin-top: -5px;
    border-radius: 6px;
    position: absolute;
    right: 6px;
  }
}

@media (max-height: 50px) {
  #MobileNudgeCard {
    display: none !important;
  }
}
