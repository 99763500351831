#ListItemGroupRow {
  cursor: pointer;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  background-color: var(--ion-color-light);
  z-index: 2 !important;

  div.row {
    align-self: center;
    margin-top: 4px;
    height: calc(var(--list-row-height) - 4px);
    width: 100%;
    background-color: var(--ion-color-medium);
    display: flex;
    padding-left: 10px;
    border-radius: 4px;
    border: solid rgba(var(--ion-color-light-contrast-rgb), 0.05) 1px;
    border-left: solid #666 3px;

    .name {
      align-self: center;
      text-transform: capitalize;
    }

    ion-icon {
      height: 100%;
      margin-left: auto;
      margin-right: 10px;
    }
  }
}

html.plt-ios {
  #ListItemGroupRow {
    .name {
      text-transform: capitalize;
    }
  }
}
