.offline-ui,
.offline-ui *,
.offline-ui:before,
.offline-ui:after,
.offline-ui *:before,
.offline-ui *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.offline-ui {
  display: none;
  position: fixed;
  background: white;
  z-index: 2000;
  display: inline-block;
}
.offline-ui .offline-ui-retry {
  display: none;
}
.offline-ui.offline-ui-up {
  display: block;
}
.offline-ui.offline-ui-down {
  display: block;
}

.offline-ui {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  font-family: 'Helvetica Neue', sans-serif;
  width: 8px;
  height: 8px;
  top: 6px;
  right: 6px
}
.offline-ui.offline-ui-up {
  display: none;
  background: #d6e9c6;
  border: solid #468847 1px;
}
.offline-ui.offline-ui-up-5s {
  display: block;
  background: #d6e9c6;
  border: solid #468847 1px;
}
.offline-ui.offline-ui-down {
  background: #d07c7c;
  border: solid #a15e5e 2px;
}
