#RecipeEditPage_Content #RecipeItemRow {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  padding-right: 8px;
  border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 20px;
  flex-wrap: wrap;

  &.link-mode {
    padding-bottom: 12px;
  }

  .suggested-items {
    display: flex;
    flex-wrap: wrap;
    justify-self: start;
  }

  .no-suggested-items {
    margin-left: 12px;
    margin-bottom: 8px;
    ion-icon {
      color: rgba(var(--ion-color-board-contrast-rgb), 0.37);
      height: 15px;
      margin-bottom: -2px;
    }
    span {
      padding-left: 4px;
      font-size: 0.85rem !important;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.67);
    }
  }

  .item-row {
    flex-grow: 1;
    margin-left: 8px;
    padding-bottom: 8px;
    display: flex;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .suggestion-loading {
    flex-basis: 100%;
    height: 30px;
    padding-left: 8px;

    ion-spinner {
      color: var(--ion-color-tertiary-tint) !important;
      font-size: 0.7rem;
      margin-left: 4px;
    }
  }

  .name {
    flex: 1;
    height: 100% !important;

    ion-textarea {
      margin-top: 0;
      height: 100% !important;
    }

    textarea {
      overflow-y: hidden;
    }
  }

  .notes {
    color: #888;
    flex: 1;
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }

  .move-btn {
    cursor: move;
    padding: 8px;

    ion-icon {
      padding-top: 5px;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.17);
    }
  }

  .del-btn {
    padding: 8px;
    cursor: pointer;

    ion-icon {
      padding-top: 5px;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.17);
    }
  }

  div.item-btn {
    cursor: pointer;
    white-space: nowrap;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;

    &.select-item {
      margin-top: 4px;
      border-radius: 10px;
      border: solid rgba(var(--ion-color-light-contrast-rgb), 0.07) 2px;
      padding-left: 8px;
      background: rgba(var(--ion-color-light-contrast-rgb), 0.02);
    }

    svg {
      margin-top: 2px;
      margin-left: -2px;
      margin-right: 3px;
      font-size: 0.9rem;
      color: var(--ion-color-tertiary-tint) !important;
    }

    ion-icon {
      margin-top: 0;
      margin-left: -2px;
      // margin-bottom: -2px;
      color: var(--ion-color-tertiary-tint) !important;
    }

    &.edit {
      svg {
        margin-top: -2px;
        color: rgba(var(--ion-color-board-contrast-rgb), 0.37) !important;
      }
      min-width: 20px !important;
    }

    div {
      margin-left: 6px;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.8);

      &.name {
        font-weight: bold;
      }
    }
  }

  div.row-btn-container {
    z-index: 1000 !important;
    background: var(--ion-color-light);
    cursor: pointer;
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-self: flex-end;

    div.row-btn {
      padding-left: 6px;
      padding-right: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      ion-icon {
        margin-top: 2px;
        margin-left: -2px;
        color: rgba(var(--ion-color-board-contrast-rgb), 0.27);
        font-size: 0.5rem !important;
      }

      div {
        margin-left: 4px;
        color: var(--ion-text-color);
      }

      &.attach-btn {
        ion-icon {
          font-size: 0.6rem !important;
          color: rgba(var(--ion-color-board-contrast-rgb), 0.37);
        }
        div {
          color: rgba(var(--ion-color-board-contrast-rgb), 0.37);
          font-size: 0.5rem;
        }
      }
    }
  }
}

div#RecipeItemRow {
  &.dragging {
    overflow-y: hidden;

    textarea {
      overflow-y: hidden;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.27);
      min-height: 50px;
      margin-top: 6px;
      padding-left: 20px;
    }
    div.textarea-wrapper:nth-child(1) {
      display: none !important;
    }
    div.row-btn-container {
      display: none !important;
    }
    i {
      display: none !important;
    }
    div.row-btn {
      display: none !important;
    }
    div.attach-btn {
      display: none !important;
    }
  }
}
