#RecipeEditPage_Content #StepRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  cursor: pointer;
  border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 20px;

  .name {
    flex: 1;
    height: 100% !important;

    ion-textarea {
      margin-top: 0;
      height: 100% !important;
      vertical-align: middle;
    }

    textarea {
      vertical-align: middle;
      overflow-y: hidden;
    }
  }

  .notes {
    color: #888;
    flex: 1;
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }

  .move-btn {
    cursor: move;
    padding: 8px;

    ion-icon {
      padding-top: 5px;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.17);
    }
  }

  .del-btn {
    ion-icon {
      color: rgba(var(--ion-color-board-contrast-rgb), 0.17);
    }
  }

  div.row-btn-container {
    z-index: 1000 !important;
    background: var(--ion-color-light);
    cursor: pointer;
    height: 38px;
    display: flex;
    flex-direction: row;
    justify-self: flex-end;

    div.row-btn {
      padding-left: 6px;
      padding-right: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      ion-icon {
        margin-top: 2px;
        margin-left: -2px;
        color: rgba(var(--ion-color-board-contrast-rgb), 0.27);
        font-size: 0.5rem !important;
      }

      div {
        margin-left: 4px;
        color: var(--ion-text-color);
      }

      &.attach-btn {
        ion-icon {
          font-size: 0.6rem !important;
          color: rgba(var(--ion-color-board-contrast-rgb), 0.37);
        }
        div {
          color: rgba(var(--ion-color-board-contrast-rgb), 0.37);
          font-size: 0.5rem;
        }
      }
    }
  }
}

div#StepRow {
  &.dragging {
    overflow-y: hidden;

 
    textarea {
      overflow-y: hidden;
      color: rgba(var(--ion-color-board-contrast-rgb), 0.27);
      min-height: 50px;
      margin-top: 6px;
      padding-left: 20px;
    }
    div.textarea-wrapper:nth-child(1) {
      display: none !important;
    }
    div.row-btn-container {
      display: none !important;
    }
    i {
      display: none !important;
    }
    div.row-btn {
      display: none !important;
    }
    div.attach-btn {
      display: none !important;
    }
  }
}
