html.plt-ios.plt-hybrid {
  #MealsListPage_Header {
    ion-toolbar {
      padding-top: 0;
    }
  }
}

#MealsListPage_Header {
  animation: slide-up 0.4s ease-in;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  ion-toolbar {
    --min-height: 46px;

    i {
      color: var(--ion-color-secondary-contrast) !important;
    }
  }
}

html.plt-ios {
  #MealsListPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
  }
}

#MealsListPage_Content {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: rgba(var(--ion-color-light-contrast-rgb), 0.8);

    i {
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }
}
