@import 'theme.all.scss';
@import 'theme.base.scss';
// @import 'theme.ionic.dark.scss';
@import 'theme.ionic.default.scss';
@import 'theme.ionic.dark2.scss';
@import 'theme.fonts.scss';
@import 'theme.mdc.scss';
@import 'theme.animations.scss';
@import 'theme.static-colors.scss';
@import 'theme.ios.scss';
@import 'theme.scrollbar.scss';

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}
