html.plt-ios.plt-hybrid {
  #ItemsListPage_Header {
    ion-toolbar {
      padding-top: 0;
    }
  }
}

#ItemsListPage_Header {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  
  ion-toolbar {
    --min-height: 46px;
  }

  i {
    color: var(--ion-color-primary-contrast) !important;
  }
}

// html.plt-ios {
//   ion-content#ItemsListPage_Content {
//     ion-list {
//       margin-top: 0;
//       z-index: 100;
//     }
//   }
// }
