#MealEditPage_Content #RecipeSelectList {
  margin-left: 12px;
  margin-right: 12px;

  ion-card-header {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
  }

  ion-title {
    font-size: 0.9rem;
    text-transform: capitalize;
    font-family: 'Lato-Light';
  }

  ion-card-content {
    padding-top: 0;
  }

  .add-button {
    ion-icon {
      margin-left: -4px;
    }
  }
}

html.plt-ios {
  #MealEditPage_Content #RecipeSelectList {
    ion-title {
      text-transform: capitalize !important;
    }
  }
}
