#ThemesListPage_Content #ThemeRow {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-left: solid 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Lato-Light';
  text-transform: capitalize;
  font-size: 0.9rem;
  align-self: center;
  color: var(--ion-text-color);
  background-color: rgba(var(--ion-color-medium-rgb), 0.25);
}

html.plt-ios {
  #ThemesListPage_Content #ThemeRow {
    text-transform: capitalize;
  }
}
