html.plt-ios.plt-hybrid {
  #RecipesListPage_Header {
    ion-toolbar {
      padding-top: 0;
    }
  }
}

#RecipesListPage_Header {
  animation: slide-up 0.4s ease-in;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  ion-toolbar {
    --min-height: 46px;

    i {
      color: var(--ion-color-secondary-contrast) !important;
    }
  }
}

#RecipesListPage_Content {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  .group-header {
    // margin: 10px;
    color: var(--ion-text-color);
    font-size: 1.2rem;
    background-color: var(--ion-color-light-tint);
    padding: 10px;
  }

  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: rgba(var(--ion-color-light-contrast-rgb), 0.8);
  }
}

html.split-pane-visible {
  #RecipesListPage_FabList {
    left: 0px;
    ion-fab-button {
      top: 0px;
    }
  }
}

#RecipesListPage_FabList {
  left: 0px;
  margin-top: -40px !important;

  ion-fab-button {
    --border-radius: 8px;
    width: 160px;
    position: relative;
    right: 120px;
    align-self: flex-end;
    top: -70px;

    &.small {
      width: 154px;
    }
    &.smaller {
      width: 86px;
    }
  }
}
