// @import '../../node_modules/@material/react-tab-bar/index.scss';
// @import '../../node_modules/@material/react-tab-scroller/index.scss';
// @import '../../node_modules/@material/react-tab/index.scss';
// @import '../../node_modules/@material/react-tab-indicator/index.scss';

@import '../../node_modules/@material/react-tab-bar/dist/tab-bar.css';
@import '../../node_modules/@material/react-tab-scroller/dist/tab-scroller.css';
@import '../../node_modules/@material/react-tab/dist/tab.css';
@import '../../node_modules/@material/react-tab-indicator/dist/tab-indicator.css';

:root {
  --mdc-theme-primary: #fff;
}
