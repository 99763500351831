#SideMenu {
  #LogoContainer {
    display: flex;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 20px;

    img {
      width: 53px;
      height: 53px;
      text-align: center;
      margin-top: 10px;
      margin-right: 12px;
      margin-left: 12px;
    }

    div#g {
      font-family: 'Lato-Regular';
      font-size: 28px !important;
      text-transform: uppercase;
      color: var(--ion-color-medium-contrast);
    }
    div#b {
      font-family: 'Lato-Regular';
      font-size: 28px !important;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--ion-color-medium-contrast);
    }
  }

  ion-buttons {
    align-self: flex-start;
    margin-top: 12px;
  }

  ion-list {
    padding-bottom: 0;
    background-color: var(--ion-color-medium);
  }

  ion-item {
    --border-color: transparent !important;
  }

  i {
    color: var(--ion-color-medium-contrast) !important;
  }

  ion-icon {
    font-size: 24px !important;
  }

  ion-label {
    margin-left: 20px;
    text-transform: capitalize;
    color: var(--ion-color-medium-contrast);
  }

  .active {
    i {
      color: var(--ion-color-tertiary-contrast) !important;
    }
    ion-item {
      background-color: var(--ion-color-tertiary);
    }
    ion-label {
      color: var(--ion-color-tertiary-contrast);
    }
  }

  ion-list-header {
    padding-left: 0;
    padding-top: 10px;
    font-weight: bold;
  }
}

html.plt-ios {
  #SideMenu {
    ion-label {
      text-transform: capitalize;
    }
    ion-list-header {
      padding-top: 10px;
    }
    .header-ios ion-toolbar:last-of-type {
      --border-width: 0 0 0;
    }
  }
}

html.plt-ios.plt-hybrid {
  #SideMenu {
    padding-top: var(--ion-safe-area-top);
  }
}

// when on desktop
html.split-pane-visible {
  #SideMenu {
    top: 0;
    max-width: 275px;

    #LogoContainer {
      padding-top: 20px;
      padding-bottom: 0;
      margin-bottom: -10px;
      margin-left: -20px;
    }

    // ion-content {
    //   --offset-bottom: -45px !important;
    // }

    ion-list {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 48px;
      padding-top: 0;
      padding-bottom: 0;
      background: transparent;

      ion-item {
        margin: 4px;
        border-radius: 8px;
      }
    }
  }
}
