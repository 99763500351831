html.plt-windows.plt-mobileweb {
  * {
    ::-webkit-scrollbar {
      width: 9px;
      height: 18px;
    }

    ::-webkit-scrollbar-thumb {
      height: 6px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }

    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
}
