.ac-container {
  border-radius: 5px;

  .ac-choiceSetInput-expanded {
    input {
      cursor: pointer;
      z-index: 1000;
      position: relative;
      right: -20px;
      visibility: hidden;
    }

    label {
      background-color: var(--ion-color-medium) !important;
      border-radius: 5px;
      padding: 10px;
      padding-left: 20px;
      margin: 4px;
      position: relative;
      left: -20px;
      cursor: pointer;
    }

    input:checked + div + label {
      background-color: var(--ion-color-tertiary) !important;
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }

  .ac-textBlock {
    line-height: unset !important;
  }

  // .ac-textBlock#other {
  //   background-color: var(--ion-color-light) !important;
  //   border-color: var(--ion-color-tertiary) !important;
  //   border-width: 2px;
  //   border-style: solid;
  //   border-radius: 5px;
  //   padding: 10px;
  //   padding-left: 20px;
  //   // margin: 4px;
  //   margin: -4px 22px 0 4px;
  //   position: relative;
  //   // left: -20px;
  //   cursor: pointer;
  // }

  .ac-input-container {
    input {
      border: solid var(--ion-color-medium) 2px;
      border-radius: 5px;
      padding: 9px;
      margin-left: 5px;
      // margin-right: 24px;
      margin-top: -4px;
    }
    .ac-input.ac-textInput {
      background-color: var(--ion-color-light) !important;
      color: var(--ion-text-color) !important;
      padding: 10px;
      border-radius: 8px;
      margin-left: 4px;
      margin-top: 1px;
      // margin-right: 20px;
    }
    .ac-input-validation-failed {
      border-color: var(--ion-color-danger) !important;
    }
  }

  .ac-pushButton,
  .ac-pushButton-disabled {
    background-color: var(--ion-color-tertiary) !important;
    border-radius: 5px;
    color: var(--ion-color-tertiary-contrast) !important;
    padding: 10px;
    margin-left: 5px;
  }
}

#recipe-options-form-v1 {
  .ac-pushButton-disabled:before {
    font-family: 'FontAwesome';
    content: '\f00c';
  }

  .ac-pushButton:before {
    font-family: 'FontAwesome';
    content: '\f1db';
  }

  .ac-pushButton[title='Next']:before {
    font-family: 'FontAwesome';
    content: '\f061';
  }

  .ac-pushButton[title='Save Recipe']:before,
  .ac-pushButton[title='Open Recipe']:before {
    content: '';
    width: 0;
    margin-right: 0;
  }

  .ac-pushButton[title='Save Recipe']:after,
  .ac-pushButton[title='Open Recipe']:after {
    color: var(--ion-color-tertiary-contrast) !important;
    width: 20px;
    margin-left: 8px;
  }

  #btnOpenRecipe .ac-pushButton:after,
  #btnSaveRecipe .ac-pushButton:after {
    font-family: 'FontAwesome';
    content: '\f061';
  }

  #btnMakeChanges .ac-pushButton:before {
    font-family: 'FontAwesome';
    content: '\f0eb';
    margin-right: 6px;
    font-size: 1rem;
    color: var(--ion-color-medium-contrast) !important;
    width: 20px;
  }

  #btnMakeChanges .ac-pushButton {
    background-color: var(--ion-color-medium) !important;
    color: var(--ion-color-medium-contrast) !important;
  }

  .ac-pushButton,
  .ac-pushButton-disabled {
    &:before {
      display: block;
      margin-right: 10px;
      font-size: 1.2rem;
      color: var(--ion-color-primary);
      width: 20px;
    }

    &.style-positive {
      width: unset;
      background-color: var(--ion-color-tertiary) !important;
      color: var(--ion-color-tertiary-contrast) !important;
    }

    background-color: var(--ion-color-medium) !important;
    color: var(--ion-color-medium-contrast) !important;
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 12px;
    padding-bottom: 11px;
    padding-top: 13px;
  }
}

#capture-list-items-voice-v1 {
  #btnRemove .ac-pushButton:before {
    font-family: 'FontAwesome';
    content: '\f00c';
  }

  #btnAdd .ac-pushButton:before {
    font-family: 'FontAwesome';
    content: '\f1db';
  }

  .ac-pushButton {
    &:before {
      display: block;
      margin-right: 10px;
      font-size: 1.2rem;
      color: var(--ion-text-color);
      width: 20px;
    }

    &.style-positive {
      background-color: var(--ion-color-tertiary) !important;
      color: var(--ion-color-tertiary-contrast) !important;
    }

    background-color: var(--ion-color-medium) !important;
    color: var(--ion-color-medium-contrast) !important;
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 12px;
    padding-bottom: 11px;
    padding-top: 13px;
  }
}

#pantry-recipe-options-form-v1 {
  #btnRemove button:before {
    font-family: 'FontAwesome';
    content: '\f00c';
  }

  #btnAdd button:before {
    font-family: 'FontAwesome';
    content: '\f1db';
  }

  #btnRecipeSelect button:before {
    font-family: 'FontAwesome';
    content: '\f10c';
  }

  #btnRecipeRemove button:before {
    font-family: 'FontAwesome';
    content: '\f192';
  }

  #btnCamera {
    button:before {
      font-family: 'FontAwesome';
      content: '\f030';
      margin-right: 0;
      font-size: 1rem;
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }

  #btnNext {
    button:before {
      font-family: 'FontAwesome';
      //plus icon
      content: '\f067';
      margin-right: 0;
      font-size: 1rem;
      color: var(--ion-color-tertiary-contrast) !important;
    }
  }

  .ac-pushButton,
  .ac-pushButton-disabled {
    &:before {
      display: block;
      margin-right: 10px;
      font-size: 1.2rem;
      color: var(--ion-text-color);
      width: 20px;
    }

    &.style-positive {
      background-color: var(--ion-color-tertiary) !important;
      color: var(--ion-color-tertiary-contrast) !important;
    }

    background-color: var(--ion-color-medium) !important;
    color: var(--ion-color-medium-contrast) !important;
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 12px;
    padding-bottom: 11px;
    padding-top: 13px;
  }
}
