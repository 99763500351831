#AppUpdateAvailableCard {
  ion-card-header {
    i {
      float: right;
      margin-top: -26px;
    }
  }

  ion-card-content {
    padding: 20px;
  }

  ion-button {
    margin-top: 20px;
  }
}
