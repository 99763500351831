#ItemEditPage_Content #DetailsForm {
  .future-section {
    .forced-floating {
      margin-bottom: 8px;
    }
    ion-text {
      margin-bottom: 6px;
    }
    .future-item {
      padding: 12px;
      padding-left: 1px;
      margin: 0;
      margin-right: 10px;
      width: 100%;
      border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.08) 1px;
    }
    .future-item:nth-child(odd) {
      background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
    }
  }

 

  #ItemRow {
    display: flex;
    align-items: center;
    border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
    cursor: pointer;
    padding: 0 14px;
    border-left: solid #666 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 45px;
    margin-left: 14px;

    .name {
      margin-left: 6px;
      flex: 1;
      align-self: center;
      line-height: 1.8rem;
      cursor: pointer;
      color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
    }

    ion-checkbox {
      --border-color-checked: rgba(var(--ion-color-light-contrast-rgb), 0.7);
      --border-color: rgba(var(--ion-color-light-contrast-rgb), 0.2);
      --checkmark-color: rgba(var(--ion-color-light), 0.6);
    }

    .for-checkbox {
      margin-left: -20.5px;
      margin-top: 0px;
      z-index: 100;
      font-size: 16px;
      opacity: 0.6;
      margin-right: 12px;
      color: var(--ion-color-light-contrast) !important;

      &.cart {
        font-size: 14px;
      }
    }

    .qty-section {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;

      .qty {
        margin-top: -1px;
      }

      .qty-button {
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
      }

      .minus-button {
        margin-right: 6px;
      }

      .plus-button {
        margin-left: 6px;
      }
    }
  }
}
