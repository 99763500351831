html.plt-ios.plt-hybrid {
  #Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
      padding-bottom: 0;
    }
  }
}

#Header {
  #HeaderToolbar {
    box-shadow: 0px 0px 4px 0px #444;
    // padding-top: 20px; // temp for iphoneX screen capture
  }

  ion-icon {
    font-size: 26px !important;
  }
}

html.split-pane-visible {
  #Header {
    left: 276px;
    width: calc(100% - 276px);
  }
}

// temp for iphoneX screen capture
// #Layout {
//   ion-header ion-toolbar {
//     padding-top: 10px;
//   }
// }
