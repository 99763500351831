html.plt-ios.plt-hybrid {
  #BotSessionEditPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #BotSessionEditPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}


#BotSessionEditPage_Header {
  animation: slide-up 0.4s ease;
}

#BotSessionEditPage_Content {
  animation: slide-up 0.4s ease;
}

