html.plt-ios.plt-hybrid {
  #ItemsSelectPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #ItemsSelectPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#ItemsSelectPage_Header {
  animation: slide-up 0.4s ease;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  i {
    color: var(--ion-color-secondary-contrast) !important;
  }
}

#ItemsSelectPage_Content {
  animation: slide-up 0.4s ease;
}
