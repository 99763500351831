html.plt-ios.plt-hybrid {
  #RecipeSelectModal {
    ion-toolbar:first-child {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

#RecipeSelectModal_Toolbar {
  padding-left: 5px;
  padding-right: 5px;

  input {
    border: solid #fff 2px;
    background: #fff;
    color: #333;
    border-radius: 5px;
    font-size: 0.85rem;
    width: 100%;
    height: 40px;
    padding-left: 8px;
    text-transform: capitalize;
    font-weight: bold;
  }

  input::placeholder {
    color: #c0c0c0;
    font-weight: normal;
  }

  div {
    margin-left: -80px;
  }

  i {
    cursor: pointer;
    position: relative;
    left: -10px;
    color: var(--ion-text-color) !important;
  }
}