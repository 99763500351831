
@font-face {
  font-family: 'Lato-Regular';
  src: url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato-Light';
  src: url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Ingredient';
  src: url("../assets/fonts/Ingredient/Lekton-Bold.ttf") format("truetype");
}

@font-face {
  font-family: AntDesign;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/AntDesign.ttf) format("truetype");
}

@font-face {
  font-family: Entypo;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Entypo.ttf) format("truetype");
}

@font-face {
  font-family: EvilIcons;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/EvilIcons.ttf) format("truetype");
}

@font-face {
  font-family: Feather;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Feather.ttf) format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/FontAwesome.ttf) format("truetype");
}

@font-face {
  font-family: Foundation;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Foundation.ttf) format("truetype");
}

@font-face {
  font-family: Ionicons;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Ionicons.ttf) format("truetype");
}

@font-face {
  font-family: MaterialCommunityIcons;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/MaterialCommunityIcons.ttf) format("truetype");
}

@font-face {
  font-family: MaterialIcons;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/MaterialIcons.ttf) format("truetype");
}

@font-face {
  font-family: Octicons;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Octicons.ttf) format("truetype");
}

@font-face {
  font-family: SimpleLineIcons;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/SimpleLineIcons.ttf) format("truetype");
}

@font-face {
  font-family: Zocial;
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Zocial.ttf) format("truetype");
}

