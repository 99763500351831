#ListItemsListPageMoreMenu {
  ion-button {
    min-width: 35px;
  }
  #spinner-container {
    display: inline-block;
    vertical-align: middle;
    min-width: 38px;
    margin-bottom: -10px;
  }
}

#ListItemsListPageMoreMenu_Content {
  ion-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  ion-item-divider {
    min-height: 0 !important;
    --background: #666 !important;
  }

  ion-item {
    cursor: pointer;
  }

  i {
    color: var(--ion-text-color) !important;
  }

  ion-icon {
    --color: var(--ion-text-color) !important;
    color: var(--ion-text-color) !important;
  }
}
