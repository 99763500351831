html.plt-ios.plt-hybrid {
  #BotSessionNewPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #BotSessionNewPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#BotSessionNewPage_Content {
  #SpinnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 40px;

    ion-spinner {
      width: 70px;
      height: 70px;
      color: gray;
    }
  }

  ion-text {
    margin: 20px;
    display: block;
    text-align: center;
    font-size: 1.1rem;
  }
}
