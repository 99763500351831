#ForgotPasswordPage {
  background-color: var(--ion-color-medium);

  #LogoContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: calc(15px + var(--ion-safe-area-top));
    max-width: 450px;
    margin: auto;

    img {
      width: 65%;
      height: 65%;
      text-align: center;
    }
  }

  ion-button#ResetPasswordButton {
    --background: #629656;
  }

  ion-button#BackButton {
    --background: var(--ion-color-primary);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    background: none;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--ion-text-color) !important;
  }

  ion-spinner {
    margin-left: 167px;
  }

  ion-text {
    text-align: center;
    text-transform: capitalize;

    h1 {
      font-size: 1.9rem;
      margin-top: 30px;
    }

    h6 {
      font-size: 0.8rem;
      cursor: pointer;
    }
  }

  ion-content {
    align-self: center;
    background: transparent !important;
    background-color: transparent !important;
    --background: none;
  }

  ion-card {
    max-width: 450px;
    margin: auto;
  }

  .forgot-password {
    display: flex;
    justify-content: center;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }
}
