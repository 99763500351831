#RecipeRow {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 14px;
  margin-left: 16px;
  width: calc(100% - 24px) !important;
  border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
  cursor: pointer;

  span {
    align-self: center;
  }
}
