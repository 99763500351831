html.plt-ios.plt-hybrid {
  #AccountSettingsPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #AccountSettingsPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#AccountSettingsPage_Header {
  animation: slide-up 0.4s ease-in;
}

#AccountSettingsPage_Content {
  animation: slide-up 0.4s ease-in;
  --padding-top: 15px;
  --padding-start: 10px;
  --padding-end: 10px;

  .body {
    margin: auto;
    max-width: 700px;
    border: solid 1px rgba(var(--ion-color-medium-contrast-rgb), 0.1);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
  }

  ion-item-divider {
    color: var(--ion-color-light-contrast);
    font-weight: bold;
    font-size: 1rem;
    border-bottom: none;
  }

  i {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    vertical-align: middle;
  }

  ion-text {
    padding-right: 8px;
  }
}
