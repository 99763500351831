#ToTopFab {
  ion-fab-button {
    width: 35px;
    height: 35px;

    ion-icon {
      font-size: 16px !important;
    }
  }
}
