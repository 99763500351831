#MealEditPage_Content #DetailsForm {
  .MuiInputBase-root {
    color: inherit;
    border-bottom: none !important;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
}

.MuiInputBase-input {
  cursor: pointer;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--ion-color-medium) !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: var(--ion-color-secondary) !important;
}

.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersTimePickerToolbar-ampmLabel.MuiTypography-subtitle1 {
  color: var(--ion-text-color) !important;
}

.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersTimePickerToolbar-ampmLabel.MuiPickersToolbarText-toolbarBtnSelected.MuiTypography-subtitle1 {
  color: var(--ion-color-tertiary) !important;
}

.MuiPickerDTTabs-tabs {
  background-color: var(--ion-color-medium) !important;
}

.MuiPickersDay-daySelected {
  background-color: var(--ion-color-tertiary) !important;
}

.MuiButton-textPrimary {
  color: var(--ion-color-tertiary) !important;
}

.MuiPickersClockPointer-pointer {
  background-color: var(--ion-color-tertiary) !important;
}

.MuiPickersClock-pin {
  background-color: var(--ion-color-tertiary) !important;
}
.MuiPickersClockPointer-noPoint {
  background-color: var(--ion-color-tertiary) !important;
}
.MuiPickersClockPointer-thumb {
  border-color: var(--ion-color-tertiary) !important;
}
