#MealEditPage_Content #RecipeItemRow {
  display: flex;
  flex-direction: column;

  .description {
    flex: 1;
    color: var(--ion-text-color);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 1px;
    border-left: solid #999 3px;
    border-top-left-radius: 2px;
    font-family: 'Ingredient';
    cursor: pointer;
  }

  .item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    padding-right: 14px;
    border-left: solid #999 3px;
    border-bottom-left-radius: 2px;

    .ion-activatable {
      position: relative;
      overflow: hidden;
    }

    .del-btn {
      ion-icon {
        color: rgba(var(--ion-color-board-contrast-rgb), 0.17);
      }
    }

    .icon-container {
      display: inline-flex;
      padding-left: 16px;
      height: 100%;
      align-items: center;
      cursor: pointer;

      i {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.2) !important;
        min-width: 22px;
      }

      &.checked {
        i {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.6) !important;
        }
      }
    }

    .cart {
      margin-left: -21px;
      margin-top: 1px;
      z-index: 100;
      font-size: 16px;
      opacity: 0.6;
      margin-right: 7px;
      color: var(--ion-color-light-contrast) !important;
      margin-left: -16px;
      font-size: 9px;
      cursor: pointer;
    }

    div.row-btn-container {
      z-index: 1000 !important;
      height: 38px;
      display: flex;
      flex-direction: row;
      justify-self: flex-end;
      margin-left: 8px;

      div.row-btn {
        padding-right: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        div.name {
          flex: 1;
          align-self: center;
          line-height: 1.8rem;
          color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
          &.checked {
            color: var(--ion-text-color);
          }
        }

        &.checked {
          .name {
            color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
          }
        }

        &.link-btn {
          div {
            color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
            font-size: 0.5rem;
          }
        }
      }
    }
  }
}
