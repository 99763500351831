#RecipeImportPage_Content {
  ion-text {
    margin-left: 20px;
    margin-right: 20px;
    display: block;
    text-align: left;

    span {
      font-weight: bold;
    }
  }

  #Processing {
    margin-left: 20px;
    margin-right: 20px;
    display: block;
    text-align: center;

    #SpinnerContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 40px;

      ion-spinner {
        width: 70px;
        height: 70px;
        color: gray;
      }
    }
  }

  #Error {
    margin-left: 20px;
    margin-right: 20px;
    display: block;
    text-align: center;

    ion-icon {
      font-size: 40px;
      margin: 20px;
    }
  }

  #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
