html.plt-ios.plt-hybrid {
  #BoardSettingsPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #BoardSettingsPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

html.plt-ios {
  #BoardSettingsPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
    ion-item-divider {
      text-transform: capitalize;
    }
  }
}

#BoardSettingsPage_Content {
  ion-list {
    padding-top: 0 !important;
  }

  ion-item-divider {
    text-transform: capitalize;
  }
}
