#CategoriesList {
  ion-toolbar {
    display: flex;
    align-items: center;
    height: 38px;
  }

  .add-button {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 36px;
    align-items: center;
    margin-top: -8px;
    cursor: pointer;
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }
}
