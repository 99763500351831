#LoginPage {
  ion-content {
    --background: var(--ion-color-medium) !important;
  }

  .or {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    display: block;
    font-size: 1.3rem;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }
  //   ion-card {
  //     max-width: 450px;
  //     margin: auto;
  //     margin-bottom: 200px;
  //   }

  //   ion-card-content {
  //     padding: 40px;
  //     padding-bottom: 20px;
  //   }

  //   input:-webkit-autofill,
  //   input:-webkit-autofill:hover,
  //   input:-webkit-autofill:focus {
  //     background: var(--ion-color-light) !important;
  //     transition: background-color 5000s ease-in-out 0s;
  //     font-family: var(--ion-font-family) !important;
  //     caret-color: var(--ion-color-light-contrast) !important;
  //     -webkit-text-fill-color: var(--ion-color-light-contrast) !important;
  //     -webkit-text-caret-color: var(--ion-color-light-contrast) !important;
  //   }

  //   ion-input {
  //     input {
  //       color: var(--ion-color-light-contrast) !important;
  //     }
  //   }

  //   ion-text {
  //     text-align: center;
  //     text-transform: capitalize;

  //     h1 {
  //       font-size: 1.9rem;
  //     }

  //     h6 {
  //       font-size: 0.8rem;
  //       cursor: pointer;
  //     }
  //   }

  //   ion-spinner {
  //     margin-left: 10px;
  //   }

  //   ion-button.login-button {
  //     --background: var(--ion-color-success-shade) !important;
  //     color: #fff !important;
  //     i {
  //       color: var(--ion-color-success-shade-contrast) !important;
  //     }
  //   }

  //   .google-button,
  //   .apple-button,
  //   .register-button {
  //     text-transform: none;
  //     --background: rgb(var(--ion-color-secondary-rgb), 0.9);
  //     --padding-top: 24px;
  //     --padding-bottom: 24px;
  //     color: var(--ion-color-secondary-contrast) !important;
  //     i,
  //     ion-icon {
  //       color: var(--ion-color-secondary-contrast) !important;
  //     }
  //   }

  //   .guest-button,
  //   .login-button {
  //     --background: rgb(var(--ion-color-medium-rgb), 0.3);
  //     --padding-top: 24px;
  //     --padding-bottom: 24px;
  //     color: var(--ion-color-medium-contrast) !important;
  //     i,
  //     ion-icon {
  //       color: var(--ion-color-medium-contrast) !important;
  //     }
  //   }

  //   .google-button {
  //     img {
  //       width: 20px;
  //       height: 20px;
  //     }
  //   }

  //   .apple-button {
  //     margin-top: 30px;
  //     img {
  //       width: 20px;
  //       height: 20px;
  //     }
  //   }

  //   .guest-button {
  //     margin-top: 30px;
  //   }

  //   .forgot-password-button {
  //     margin-top: 20px;
  //     display: flex;
  //     justify-content: center;
  //     color: var(--ion-color-gray) !important;
  //     text-transform: capitalize;
  //   }

  //   .trouble-button {
  //     margin-top: 40px;
  //     display: flex;
  //     justify-content: center;
  //     color: var(--ion-color-gray) !important;
  //     text-transform: capitalize;
  //   }

  //   .error {
  //     margin-top: 10px;
  //     display: flex;
  //     justify-content: center;
  //   }

  // html.plt-ios {
  //   #LoginPage {
  //     ion-text.forgot-password {
  //       text-transform: capitalize !important;
  //     }

  //     #LogoContainer {
  //       padding-bottom: 0;
  //     }

  //     .or {
  //       margin-top: 20px;
  //       margin-bottom: 0;
  //     }

  //     .google-button,
  //     .apple-button {
  //       font-weight: bold;
  //       border: solid #444 1px;
  //       border-radius: 2px;
  //     }

  //     ion-item {
  //       --padding-start: 0;
  //       --inner-padding-end: 0;
  //     }

  //     // ion-label {
  //     //   transform: translate3d(0, 10%, 0) scale(0.82) !important;
  //     // }

  //     // ion-input {
  //     //   border: solid #777 1px;
  //     //   border-radius: 2px;
  //     //   padding-left: 10px;
  //     // }

  //     ion-card {
  //       margin-top: 0;
  //     }

  //     ion-card-content {
  //       padding-top: 10px;
  //     }
  //   }
}
