// @import '../../../../node_modules/yet-another-react-lightbox/dist/styles.css';

#ImagesGrid {
  box-sizing: border-box;

  &.colcnt-1 {
    .grid-container {
      column-count: 1;
    }
  }
  &.colcnt-2 {
    .grid-container {
      column-count: 2;
    }
  }
  &.colcnt-3 {
    .grid-container {
      column-count: 3;
    }
  }
  &.colcnt-4 {
    .grid-container {
      column-count: 4;
    }
  }
  &.colcnt-5 {
    .grid-container {
      column-count: 5;
    }
  }
  &.colcnt-6 {
    .grid-container {
      column-count: 6;
    }
  }
  &.auto {
    .grid-container {
      column-count: 5;
    }
  }

  .grid-container {
    column-gap: 10px;
    padding: 10px;

    .grid-item {
      cursor: pointer;
      break-inside: avoid;
      margin-bottom: 10px;
      background-color: #fff;
      border: 1px solid var(--ion-color-light-shade);
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      text-align: center;
      vertical-align: middle;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1200px) {
  #ImagesGrid.auto {
    .grid-container {
      column-count: 4;
    }
  }
}
@media (max-width: 800px) {
  #ImagesGrid.auto {
    .grid-container {
      column-count: 3;
    }
  }
}
@media (max-width: 600px) {
  #ImagesGrid.auto {
    .grid-container {
      column-count: 2;
    }
  }
}
