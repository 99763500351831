#BoardMenu {
  .UserAvatar {
    font-size: 14px !important;
    flex: 1 !important;
    align-content: center !important;
    align-items: center !important;
  }

  .UserAvatar--inner {
    font-size: 14px !important;
    flex: 1 !important;
    align-content: center !important;
    align-items: center !important;
  }

  ion-button {
    height: 40px;
  }

  i {
    color: var(--ion-color-primary-contrast) !important;
  }
}

#BoardMenu_Content {
  ion-list {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  i {
    color: var(--ion-text-color) !important;
  }

  ion-item-divider {
    min-height: 0 !important;
    --background: #666 !important;
  }

  ion-item {
    cursor: pointer;
  }
}

html.plt-ios {
  #BoardMenu {
    .UserAvatar {
      font-size: 13px !important;
    }

    .UserAvatar--inner {
      font-size: 13px !important;
      line-height: 32px !important;
      max-width: 32px !important;
      width: 32px !important;
      max-height: 32px !important;
      height: 32px !important;
    }
  }
}
