#DayGroup {
  margin: 6px;
  flex: auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  &.today {
    ion-card-title {
      color: rgba(var(--ion-color-tertiary-rgb), 1);
    }
  }

  &.prior {
    ion-card-title {
      color: rgba(var(--ion-color-board-contrast-rgb), 0.5);
    }
  }

  ion-card-header {
    padding: 8px 12px;
  }

  ion-card-content {
    padding: 0 !important;
    padding-bottom: 10px !important;
    flex: 1;
    // padding-bottom: 38px !important;
    background-color: rgba(var(--ion-color-board-rgb), 0.25);

    .no-meals {
      text-align: center;
      padding: 14px 0;
      font-size: 0.7rem;
    }

    #meals-container {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      padding-bottom: 10px;
      padding-bottom: 0;

      .meal {
        flex: auto;
        margin: 4px;
        border-radius: 4px;
        border: solid var(--ion-color-board-shade) 1px;
        background-color: var(--ion-color-board-tint);
        padding: 6px;
        cursor: pointer;
        display: flex;
        white-space: nowrap;
        flex-direction: column-reverse;

        ion-text {
          color: var(--ion-color-board-contrast);
          // word-wrap: break-word;
        }

        .meal-category {
          text-transform: capitalize;
        }
      }

      .unplanned-meal {
        // flex: auto;
        margin: 4px;
        border-radius: 4px;
        border: dotted var(--ion-color-board-shade) 1px;
        background-color: rgba(var(--ion-color-board-rgb), 0.25);
        padding: 6px;
        cursor: pointer;
        display: flex;
        white-space: nowrap;
        flex-direction: column-reverse;
        align-items: center;

        ion-text {
          color: rgba(var(--ion-color-board-contrast-rgb), 0.5);
          font-size: 0.7rem;
          text-transform: capitalize;
        }
        ion-icon {
          color: rgba(var(--ion-color-board-contrast-rgb), 0.5) !important;
          margin: 4px;
        }
      }
    }

    div.add-meal-row {
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding-right: 12px;
      // height: 100%;
      min-height: 30px;

      ion-chip {
        position: absolute;
        bottom: 10px;
        margin-bottom: -8px;
        // padding: 0;
        // height: 30px;
        // padding-top: 6px;
        // padding-bottom: 6px;
      }
    }
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    font-size: 0.8rem !important;
    min-width: 120px;

    .day {
      flex: 1;
    }
  }
}

html.plt-ios {
  #DayGroup {
    ion-card-title {
      .day,
      .date {
        text-transform: capitalize;
      }
    }

    ion-card-content {
      #meals-container {
        .meal {
          .meal-category {
            text-transform: capitalize;
          }
        }

        .unplanned-meal {
          ion-text {
            text-transform: capitalize;
          }
        }
      }

      .title-row {
        text-transform: capitalize;
      }
    }
  }
}
