html.plt-ios.plt-hybrid {
  #SettingsPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #SettingsPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#SettingsPage_Header {
  animation: slide-up 0.4s ease-in;
}

#SettingsPage_Content {
  animation: slide-up 0.4s ease-in;
  --padding-top: 15px;
  --padding-start: 10px;
  --padding-end: 10px;

  .body {
    margin: auto;
    max-width: 700px;
    border: solid 1px rgba(var(--ion-color-medium-contrast-rgb), 0.1);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
  }

  ion-label.ios {
    color: var(--ion-color-light-contrast) !important;
  }

  ion-item {
    cursor: pointer;
  }

  ion-text {
    text-transform: capitalize;
  }

  ion-list.md {
    padding-top: 0;
  }

  ion-list-header.md {
    margin-top: 20px;
  }

  ion-list-header {
    ion-label {
      font-size: 1.1rem !important;
      font-weight: bold;
    }
  }

  i {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    vertical-align: middle;
  }

  .current-board-row {
    font-weight: bold;
  }

  .sign-out-button {
    // margin-top: 20px;
    margin: 20px;

    ion-label {
      color: var(--ion-color-danger-contrast) !important;
      // padding-top: 4px;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      margin-top: -4px !important;
      color: var(--ion-color-danger-contrast) !important;
    }
  }
}
