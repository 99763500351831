html.plt-ios.plt-hybrid {
  #ItemNewModal_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

#ItemNewModal {
  ion-grid {
    padding: 0 !important;
    ion-col {
      padding: 0 !important;
    }
  }

  ion-item.add-section {
    cursor: pointer;
    --min-height: 56px;
  }

  ion-checkbox {
    display: none;
  }

  .icon-container {
    cursor: pointer;
    display: inline-flex;
    padding-right: 16px;
    height: 100%;
    align-items: center;

    i {
      color: rgba(var(--ion-color-light-contrast-rgb), 0.2) !important;
      min-width: 22px;
    }

    &.checked {
      i {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.6) !important;
      }
    }
  }

  .qty-section {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-right: 20px;
    border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.25) 1px;

    .qty {
      margin-top: -1px;
    }

    .qty-button {
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      cursor: pointer;
    }

    .minus-button {
      margin-right: 6px;
    }

    .plus-button {
      margin-left: 6px;
    }
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }
}
