html.plt-ios {
  #ItemsSelect_Toolbar {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 5px;
  }  
}

#ItemsSelect_Toolbar {
  animation: slide-up 0.4s ease;
  padding-left: 6px;
  padding-right: 6px;

  input {
    border: solid var(--ion-color-light-tint) 2px;
    background: var(--ion-color-light);
    color: var(--ion-text-color);
    border-radius: 5px;
    font-size: 0.85rem;
    width: 100%;
    height: 40px;
    padding-left: 8px;
    font-weight: bold;
  }

  input::placeholder {
    color: #c0c0c0;
    font-weight: normal;
  }

  div {
    margin-left: -80px;
  }

  i {
    cursor: pointer;
    position: relative;
    left: -10px;
    color: var(--ion-text-color) !important;
  }
}