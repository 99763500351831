// @import '../../../../node_modules/yet-another-react-lightbox/dist/styles.css';

#ItemsList {
  div.item-row {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 14px;
    border-bottom: solid var(--ion-color-light-shade) 1px;
    cursor: pointer;

    span {
      align-self: center;
    }

    div.quantity {
      font-size: 1rem;
      margin-top: -4px;
      padding-left: 6px;
      padding-right: 6px;
      color: var(--ion-color-tertiary) !important;
    }

    div.right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    button {
      align-self: center;
      height: 28px;
      width: 30px;
      background-color: var(--ion-color-light);

      i {
        color: var(--ion-color-tertiary) !important;
      }
    }
  }

  div.group-row {
    div.right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    button {
      align-self: center;
      height: 28px;
      width: 30px;
    }
  }

  div.add-button-row {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  div.add-item-row {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 14px;
    border-top: solid var(--ion-color-light-shade) 1px;

    span {
      align-self: center;
    }
  }

  ion-item-divider {
    padding: 8px;
    padding-left: 16px;
    text-transform: capitalize;
  }
}
